import React from "react";
import { ChatMode } from "../types/Types";

type ChatModeSwitchProps = {
    chatMode: ChatMode;
    setChatMode: (value: ChatMode) => void;
}

export const ChatModeSwitch: React.FC<ChatModeSwitchProps> = ({ chatMode, setChatMode }) => {
    return (
        <div className="flex items-center mx-auto">
            <div className="flex rounded-full border border-gray-300 bg-black divide-x">
                <button
                    onClick={() => setChatMode(ChatMode.TEXT)}
                    className={`flex items-center justify-center text-md font-medium px-4 py-1 rounded-l-full transition-all ${chatMode === ChatMode.TEXT ? "text-white" : "bg-white text-gray-600"
                        }`}
                >

                    <svg className={'me-2'} width="17" height="15" viewBox="0 0 17 15"
                        fill="transparent" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M0.773438 0.9375C0.773438 0.68886 0.892645 0.450403 1.10484 0.274587C1.31703 0.0987719 1.60482 0 1.9049 0H15.4825C15.7826 0 16.0704 0.0987719 16.2826 0.274587C16.4947 0.450403 16.614 0.68886 16.614 0.9375V2.8125C16.614 3.06114 16.4947 3.2996 16.2826 3.47541C16.0704 3.65123 15.7826 3.75 15.4825 3.75C15.1824 3.75 14.8946 3.65123 14.6824 3.47541C14.4702 3.2996 14.351 3.06114 14.351 2.8125V1.875H9.82516V13.125H11.7109C12.011 13.125 12.2988 13.2238 12.511 13.3996C12.7232 13.5754 12.8424 13.8139 12.8424 14.0625C12.8424 14.3111 12.7232 14.5496 12.511 14.7254C12.2988 14.9012 12.011 15 11.7109 15H5.67646C5.37637 15 5.08858 14.9012 4.87639 14.7254C4.6642 14.5496 4.54499 14.3111 4.54499 14.0625C4.54499 13.8139 4.6642 13.5754 4.87639 13.3996C5.08858 13.2238 5.37637 13.125 5.67646 13.125H7.56223V1.875H3.03637V2.8125C3.03637 3.06114 2.91716 3.2996 2.70497 3.47541C2.49278 3.65123 2.20499 3.75 1.9049 3.75C1.60482 3.75 1.31703 3.65123 1.10484 3.47541C0.892645 3.2996 0.773438 3.06114 0.773438 2.8125V0.9375Z"
                            fill={chatMode === ChatMode.TEXT ? "white" : "#121212"} />
                    </svg>
                    Text
                </button>
                <button
                    onClick={() => setChatMode(ChatMode.VIDEO)}
                    className={`flex items-center justify-center text-md font-medium px-4 py-1 transition-all ${chatMode === ChatMode.VIDEO ? "text-white" : "bg-white text-gray-600"
                        }`}
                >

                    <svg className={'me-2'} width="18" height="13" viewBox="0 0 18 13" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M3.375 0.25C2.5462 0.25 1.75134 0.57924 1.16529 1.16529C0.57924 1.75134 0.25 2.5462 0.25 3.375V9.625C0.25 10.4538 0.57924 11.2487 1.16529 11.8347C1.75134 12.4208 2.5462 12.75 3.375 12.75H9.625C10.4538 12.75 11.2487 12.4208 11.8347 11.8347C12.4208 11.2487 12.75 10.4538 12.75 9.625V9.46625L15.2937 11.2225C15.5282 11.3843 15.8024 11.4789 16.0868 11.4962C16.3711 11.5134 16.6547 11.4526 16.907 11.3202C17.1593 11.1879 17.3706 10.9892 17.518 10.7455C17.6655 10.5018 17.7436 10.2224 17.7437 9.9375V3.065C17.7436 2.78014 17.6655 2.50075 17.518 2.25704C17.3706 2.01333 17.1593 1.81457 16.907 1.68226C16.6547 1.54995 16.3711 1.48911 16.0868 1.50634C15.8024 1.52357 15.5282 1.6182 15.2937 1.78L12.75 3.53625V3.375C12.75 2.5462 12.4208 1.75134 11.8347 1.16529C11.2487 0.57924 10.4538 0.25 9.625 0.25H3.375ZM12.75 5.055L16.005 2.8075C16.0519 2.77512 16.1068 2.75619 16.1637 2.75276C16.2206 2.74934 16.2774 2.76155 16.3279 2.78807C16.3783 2.81459 16.4206 2.85441 16.4501 2.90323C16.4795 2.95204 16.4951 3.00799 16.495 3.065V9.9375C16.4948 9.99441 16.4791 10.0502 16.4496 10.0988C16.42 10.1475 16.3777 10.1871 16.3273 10.2135C16.2769 10.2398 16.2202 10.2519 16.1634 10.2485C16.1066 10.245 16.0518 10.2261 16.005 10.1938L12.75 7.9475V5.055ZM1.5 3.375C1.5 2.87772 1.69754 2.40081 2.04917 2.04917C2.40081 1.69754 2.87772 1.5 3.375 1.5H9.625C10.1223 1.5 10.5992 1.69754 10.9508 2.04917C11.3025 2.40081 11.5 2.87772 11.5 3.375V9.625C11.5 10.1223 11.3025 10.5992 10.9508 10.9508C10.5992 11.3025 10.1223 11.5 9.625 11.5H3.375C2.87772 11.5 2.40081 11.3025 2.04917 10.9508C1.69754 10.5992 1.5 10.1223 1.5 9.625V3.375Z"
                            fill={chatMode === ChatMode.VIDEO ? "white" : "#121212"} />
                    </svg>
                    Video
                </button>
                <button
                    onClick={() => setChatMode(ChatMode.LIVE_CHAT)}
                    className={`flex items-center justify-center text-md font-medium px-4 py-1 transition-all ${chatMode === ChatMode.LIVE_CHAT ? "text-white" : "bg-white text-gray-600"
                        }`}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke={chatMode === ChatMode.LIVE_CHAT ? "white" : "#121212"} className="size-6 me-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M9.348 14.652a3.75 3.75 0 0 1 0-5.304m5.304 0a3.75 3.75 0 0 1 0 5.304m-7.425 2.121a6.75 6.75 0 0 1 0-9.546m9.546 0a6.75 6.75 0 0 1 0 9.546M5.106 18.894c-3.808-3.807-3.808-9.98 0-13.788m13.788 0c3.808 3.807 3.808 9.98 0 13.788M12 12h.008v.008H12V12Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                         />
                    </svg>
                    Live Chat
                </button>
                <button
                    onClick={() => setChatMode(ChatMode.PITCH_FABRICE)}
                    className={`flex items-center justify-center text-md font-medium px-4 py-1 rounded-r-full transition-all ${chatMode === ChatMode.PITCH_FABRICE ? "text-white" : "bg-white text-gray-600"
                        }`}
                >
                    Pitch Me
                </button>
            </div>
        </div>
    );
};