/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from "react";
import logo from "./assets/images/logo.png";
import fabriceAI from "./assets/images/fabrice-ai-logo.svg";
import newChat from "./assets/images/new-chat.svg";
import toggleNavbar from "./assets/images/sidebar-toggle.svg";
import packageJson from "../package.json";
import mic from "./assets/images/mic.svg";
import send from "./assets/images/send.svg";
import sendDisabled from './assets/images/send-disabled.svg';
import scrollDown from './assets/images/scroll-down.svg';
import userChat from './assets/images/user-chat.svg';
import aiChat from './assets/images/ai-chat.svg';
import audioWave from './assets/images/audio-wave.gif';
import {usePersistedState} from "./app/hooks";
import {categorizeAndGroupData, extractLastCompleteObject, parseMetadata, separateObjects} from "./utils/Utils";
import Markdown from "react-markdown";
import {LazyLoadImage} from "react-lazy-load-image-component";
import DeviceUUID from 'device-uuid';
import {AvatarState, ChatData, ChatMode, Language, RequestBody} from "./types/Types";
import {ChatModeSwitch} from "./components/ChatModeSwitch";
import {useVoiceVisualizer, VoiceVisualizer} from "react-voice-visualizer";
import {AiFillCheckCircle, AiFillCloseCircle} from "react-icons/ai";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {AvatarComponent, AvatarComponentRef} from "./components/AvatarComponent";
import useCurrentHeight from "./hooks/useCurrentHeight";
import {languages, LanguageSelection} from "./components/LanguageSelection";
import listening from "./assets/images/listening.gif";
import processing from "./assets/images/processing.gif";
import speaking from "./assets/images/speaking.gif";
import {faro} from "@grafana/faro-react";
import {getWaveBlob} from "./components/wavBlob";



function App() {
    const questions = ['What is FJ Labs\' purpose?', 'What does FJ Labs\' invest in?', 'Why is Fabrice optimistic about climate change?',
        'What are the biggest mistakes marketplace founders make?', 'How to make the most important decisions in your life?', 'How to live asset light?',
        'What are fair Seed & Series A valuations?', 'What is the key to happiness?'];
    const deviceId = new DeviceUUID.DeviceUUID().get();
    const [openNavbar, setOpenNavbar] = useState(true);
    const [prompt, setPrompt] = useState("");
    const [showScrollButton, setShowScrollButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const windowHeight = useCurrentHeight();
    const scrollableRef = useRef<HTMLDivElement>(null);
    const dataStreamController = useRef<AbortController | null>(null);
    const metaDataController = useRef<AbortController | null>(null);
    const transcriptDataController = useRef<AbortController | null>(null);
    const defaultChat: ChatData = {
        selected: 1,
        chats: [
            {
                id: 1,
                createdAt: new Date().toISOString(),
                messages: []
            }
        ]
    }
    const [chatData, setChatData] = usePersistedState("chatData", defaultChat);
    const selectedChat = chatData.chats.find((chat) => chat.id === chatData.selected);
    const [chatMode, setChatMode] = useState(ChatMode.TEXT);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [dataStreamLoading, setDataStreamLoading] = useState(false);

    const recorderControls = useVoiceVisualizer();
    const {
        recordedBlob,
        error,
        startRecording,
        stopRecording,
        isRecordingInProgress,
        clearCanvas,
        recordingTime

    } = recorderControls;
    const [transcripting, setTranscripting] = useState(false);
    const [greet, setGreet] = useState(true);
    const avatarComponentRef = useRef<AvatarComponentRef>(null);
    const [avatarState, setAvatarState] = useState<AvatarState>(AvatarState.IDLE);
    const [selectedLanguage, setSelectedLanguage] = useState<Language>(languages[0]);



    const submitPrompt = (async (prompt: string) => {
        scrollToBottom();
        setChatData((prevChatData) => {
            const newChatData: ChatData = JSON.parse(JSON.stringify(prevChatData));
            const selectedChat = newChatData.chats.find((chat) => chat.id === newChatData.selected);
            if (selectedChat) {
                selectedChat.messages.push(
                    {
                        byUser: true,
                        message: prompt,
                        topSources: [],
                        status: 'complete'
                    },
                    {
                        byUser: false,
                        message: '',
                        topSources: [],
                        status: 'incomplete'
                    });
            }

            return newChatData;
        });

        fetchApis(prompt);
    });

    const updateChatsWithStatus = (status: string) => {
        setChatData((prevChatData) => {
            const newChatData: ChatData = JSON.parse(JSON.stringify(prevChatData));
            const selectedChat = newChatData.chats.find((chat) => chat.id === newChatData.selected);
            if (selectedChat) {
                const lastMessageIndex = selectedChat.messages.length - 1;
                if (lastMessageIndex >= 0) {
                    selectedChat.messages[lastMessageIndex].status = status;
                }
            }
            return newChatData;
        });
    }

    const fetchApis = async (prompt: string) => {
        setLoading(true);
        const request = {
            query: prompt,
            deviceId: deviceId,
            tokenId: selectedChat?.tokenId ?? deviceId + '-' + Date.now(),
            threadId: selectedChat?.threadId
        };
        try {
            await Promise.all([fetchDataStream(request), fetchMetaData(request)])
        } catch (error: any) {
            console.log(error);
            if (error.name === 'AbortError') {
                updateChatsWithStatus('abort');
                faro.api.pushError(error, {
                    type: 'network',
                    context: {
                        message: "Request aborted"
                    }
                });
            } else {
                updateChatsWithStatus('error');
            }
        } finally {
            setLoading(false);
            setChatData((prevChatData) => {
                const newChatData: ChatData = JSON.parse(JSON.stringify(prevChatData));
                const selectedChat = newChatData.chats.find((chat) => chat.id === newChatData.selected);
                if (selectedChat) {
                    const lastMessageIndex = selectedChat.messages.length - 1;
                    if (lastMessageIndex >= 0 && selectedChat.messages[lastMessageIndex].status === 'incomplete') {
                        selectedChat.messages[lastMessageIndex].status = 'complete';
                    }
                }
                return newChatData;
            });
        }
    }

    const fetchDataStream = async (requestBody: RequestBody) => {
        setDataStreamLoading(true);
        dataStreamController.current = new AbortController();
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/stream`, {
            signal: dataStreamController.current?.signal,
            method: "POST", headers: {
                "Content-Type": "application/json",
            }, body: JSON.stringify(requestBody),
        });
        if (!response.ok || !response.body) return;
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        while (true) {
            const {done, value} = await reader?.read();
            if (done || dataStreamController.current?.signal?.aborted) {
                break;
            }
            const chunk = decoder.decode(value, {stream: true});
            const objects = separateObjects(chunk);
            const threadId = objects[0].threadId;
            const message = objects.map(value => value.response).join('');
            setLoadingMessage((prevState) => prevState + message);
            setChatData((prevChatData) => {
                const newChatData: ChatData = JSON.parse(JSON.stringify(prevChatData));
                const selectedChat = newChatData.chats.find((chat) => chat.id === newChatData.selected);
                if (selectedChat) {
                    if (threadId) selectedChat.threadId = threadId;
                    if (!selectedChat.tokenId) selectedChat.tokenId = requestBody.tokenId;
                    const lastMessageIndex = selectedChat.messages.length - 1;
                    if (lastMessageIndex >= 0) {
                        selectedChat.messages[lastMessageIndex].message += message;
                    }
                }
                return newChatData;
            });
        }
        setDataStreamLoading(false);
    }

    const fetchMetaData = async (requestBody: RequestBody) => {
        let buffer = '';
        metaDataController.current = new AbortController();
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/metadataStream`, {
            signal: metaDataController.current?.signal,
            method: "POST", headers: {
                "Content-Type": "application/json",
            }, body: JSON.stringify(requestBody), // body data type must match "Content-Type" header
        });
        if (!response.ok || !response.body) return;
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        let lastCompleteObjects: any[] | null = [];
        while (true) {
            const {done, value} = await reader.read();
            if (done || metaDataController.current?.signal.aborted) break;
            const chunk = decoder.decode(value, {stream: true});
            buffer += separateObjects(chunk).filter(v => v.response !== '```' && v.response !== 'json').map(value => value.response).join('');
            const lastObjects = extractLastCompleteObject(buffer);
            if (lastObjects && lastObjects.length !== lastCompleteObjects?.length &&
                !lastCompleteObjects.some(value1 => lastObjects[lastObjects.length - 1]?.link === value1.link)) {
                lastCompleteObjects = lastObjects;
                console.log('lastCompleteObject', lastCompleteObjects);
                const parsedMetadata = await parseMetadata([lastCompleteObjects[lastCompleteObjects.length - 1]]);
                setChatData((prevChatData) => {
                    const newChatData: ChatData = JSON.parse(JSON.stringify(prevChatData));
                    const selectedChat = newChatData.chats.find((chat) => chat.id === newChatData.selected);
                    if (selectedChat) {
                        const lastMessageIndex = selectedChat.messages.length - 1;
                        if (lastMessageIndex >= 0) {
                            selectedChat.messages[lastMessageIndex].topSources.push(parsedMetadata);
                        }
                    }
                    return newChatData;
                });
            }
        }
    }

    const stopApis = () => {
        if (isRecordingInProgress) stopRecording();
        if (loading) {
            dataStreamController.current?.abort();
            metaDataController.current?.abort();
        }
        if (transcripting) transcriptDataController.current?.abort();
    }

    const switchChat = (id: number) => {
        if (isRecordingInProgress) stopRecording();
        stopApis();
        setGreet(true);
        setShowScrollButton(false);
        setChatData((prevChatData) => {
            const newChatData: ChatData = JSON.parse(JSON.stringify(prevChatData));
            newChatData.selected = id;
            return newChatData;
        });
    };

    const startNewChat = () => {
        if (isRecordingInProgress) stopRecording();
        stopApis();
        setGreet(true);
        setShowScrollButton(false);
        setChatData((prevChatData) => {
            const newChatData: ChatData = JSON.parse(JSON.stringify(prevChatData));
            newChatData.chats.push({
                id: newChatData.chats.length + 1,
                createdAt: new Date().toISOString(),
                messages: []
            });
            newChatData.selected = newChatData.chats.length;
            return newChatData;
        });
    }

    useEffect(() => {

        console.log('recordingTime', recordingTime);
        if (recordingTime >= 240000) {
            stopRecording();

        }
    }, [recordingTime]);

    useEffect(() => {
        console.log('recordedBlob', recordedBlob);

        const transcriptVoice = async () => {
            if (!recordedBlob) return;
            setTranscripting(true);
            const mWavBlob = await getWaveBlob(recordedBlob, false);
            // await sleep(3000);
            transcriptDataController.current = new AbortController();
            const formData = new FormData();
            formData.append("file", mWavBlob, `${new Date().toISOString().replace(/[:.]/g, '-')}.wav`);
            formData.append("deviceId", deviceId);
            formData.append("tokenId", selectedChat?.tokenId ?? deviceId + '-' + Date.now());
            try {
                const textResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/uploadAudio`, {
                    signal: transcriptDataController.current?.signal,
                    method: "POST",
                    body: formData
                });
                const textJson = await textResponse.json();
                clearCanvas();
                submitPrompt(textJson.transcription_text);
                scrollToBottom();
            } catch (error) {

            } finally {
                setTranscripting(false);
            }


        }
        if (recordedBlob && !isRecordingInProgress) {
            transcriptVoice();
        }
    }, [isRecordingInProgress, recordedBlob]);

    const handleScroll = () => {
        const scrollable = scrollableRef.current;
        if (!scrollable) return;

        const isAtBottom =
            scrollable.scrollHeight - scrollable.scrollTop === scrollable.clientHeight;
        setShowScrollButton(!isAtBottom);
    };

    const scrollToBottom = () => {
        scrollableRef.current?.scrollTo({
            top: scrollableRef.current.scrollHeight,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        const scrollable = scrollableRef.current;
        scrollable?.addEventListener("scroll", handleScroll);

        return () => {
            scrollable?.removeEventListener("scroll", handleScroll);
        };
    }, []);

    /* return (
      <div className="grid grid-rows-12"
      style={{
        height: windowHeight
      }}>
        <div className="row-span-1 bg-amber-700">1</div>
        <div className="row-span-11 bg-cyan-600">2</div>
      </div>
    ); */


    return (

        <div className="flex flex-col divide-y divide-gray-400"
             style={{
                 height: windowHeight
             }}
        >
            {
                openNavbar
                && <div className="lg:hidden fixed top-0 left-0 w-full h-screen bg-black bg-opacity-60 z-10"
                        onClick={() => setOpenNavbar(!openNavbar)}/>
            }
            <nav>
                <div className="flex max-w-[1000px] p-4 mx-auto justify-between items-center">
                    <button type="button" className="lg:hidden" onClick={() => setOpenNavbar(!openNavbar)}>
                        <img
                            className={`size-7 lg:opacity-0 transition-transform duration-300 ${openNavbar ? "" : "rotate-y-180"}`}
                            src={toggleNavbar} alt="toggleNavbar"/>
                    </button>
                    <a className="link" href="https://fabricegrinda.com/">
                        <img className="size-16" src={logo} alt="logo"/>
                    </a>
                    <a className="link hidden lg:block" href="/">
                        <img className="w-[200px] h-[46px]" src={fabriceAI} alt="fabriceAI"/>
                    </a>
                    <button type="button"
                            disabled={chatData?.chats?.at(chatData?.chats?.length - 1)?.messages?.length === 0}
                            onClick={startNewChat}>
                        <img
                            className={`size-7 ${chatData?.chats?.at(chatData?.chats?.length - 1)?.messages?.length === 0 ? "opacity-30" : ""} lg:opacity-0`}
                            src={newChat} alt="newChat"/>
                    </button>
                </div>
            </nav>
            <div className="flex-grow overflow-y-hidden">
                <div className="flex h-full max-w-[1000px] mx-auto px-4 pt-4">
                    <div className={`flex flex-col`}>
                        <div className="hidden lg:flex min-w-20 justify-between items-center pr-4">
                            <button type="button" onClick={() => setOpenNavbar(!openNavbar)}>
                                <img
                                    className={`size-7 transition-transform duration-300 ${openNavbar ? "" : "rotate-y-180"}`}
                                    src={toggleNavbar} alt="toggleNavbar"/>
                            </button>
                            <button type="button" className="pl-4"
                                    disabled={chatData?.chats?.at(chatData?.chats?.length - 1)?.messages?.length === 0}
                                    onClick={startNewChat}>
                                <img
                                    className={`size-7 ${chatData?.chats?.at(chatData?.chats?.length - 1)?.messages?.length === 0 ? "opacity-30" : ""}`}
                                    src={newChat} alt="newChat"/>
                            </button>
                        </div>
                        <div
                            className={`z-10 bg-white flex flex-col flex-grow overflow-hidden absolute h-full ${openNavbar ? "w-3/4" : "w-0"} lg:transition-all duration-300 top-0 left-0 lg:relative lg:h-fit ${openNavbar ? "lg:w-[300px]" : "lg:w-0"}`}>
                            <div className="w-full flex lg:hidden justify-between items-center p-3">
                                <button type="button" onClick={() => setOpenNavbar(!openNavbar)}>
                                    <img
                                        className={`size-7 transition-transform duration-300 ${openNavbar ? "" : "rotate-y-180"}`}
                                        src={toggleNavbar} alt="toggleNavbar"/>
                                </button>
                                <button type="button" className="pl-4"
                                        disabled={chatData?.chats?.at(chatData?.chats?.length - 1)?.messages?.length === 0}>
                                    <img
                                        className={`size-7 ${chatData?.chats?.at(chatData?.chats?.length - 1)?.messages?.length === 0 ? "opacity-30" : ""}`}
                                        src={newChat} alt="newChat"/>
                                </button>
                            </div>
                            <div className="flex-grow overflow-y-auto custom-scrollbar sidebar">
                                <ul className="p-3">
                                    {
                                        Object.entries(categorizeAndGroupData(chatData.chats)).reverse().map(([group, items], index) => (
                                            <li key={index}>
                                                <h2 className="text-base text-gray-500 font-medium">{group}</h2>
                                                <ul className="ml-2">
                                                    {
                                                        items.reverse().map((chat) => (
                                                            <li key={chat.id} className={`cursor-pointer rounded-md p-2
                          truncate my-4 ${chat.id === chatData.selected && "bg-gray-200"} hover:bg-gray-200`}
                                                                onClick={() => switchChat(chat.id)}>{chat.messages?.at(0)?.message ?? "New Chat"}
                                                            </li>
                                                        ))
                                                    }
                                                </ul>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <small className="bg-white w-fit mx-auto">v{packageJson.version}</small>
                        </div>
                    </div>
                    <main className="flex-grow flex flex-col relative">
                        {
                            !(chatMode === ChatMode.TEXT || chatMode === ChatMode.PITCH_FABRICE) ?
                                <div className="flex-grow">
                                    <AvatarComponent
                                        ref={avatarComponentRef}
                                        index={0}
                                        contentLoading={dataStreamLoading}
                                        isLastIndex={true}
                                        chatMode={chatMode}
                                        message={''}
                                        loadingMessage={loadingMessage.replace("NO_RELEVANT_DATA", "")}
                                        clearLoadingMessage={() => {
                                            setLoadingMessage('');
                                        }}
                                        updateUserMessage={submitPrompt}
                                        greet={greet}
                                        setGreet={setGreet}
                                        avatarState={avatarState}
                                        setAvatarState={setAvatarState}
                                        language={selectedLanguage}
                                    />
                                </div> : <div ref={scrollableRef}
                                              className="flex flex-col flex-grow relative scroll-smooth overflow-y-scroll pb-12 lg:px-5">
                                    {(selectedChat?.messages.length ?? 0) > 0 ? <>{
                                        selectedChat?.messages.map((message, index) => (
                                            <article key={index} className="py-2">
                                                <div><img className="size-6 float-left me-2"
                                                          src={message.byUser ? userChat : aiChat}
                                                          alt={message.byUser ? "userChat" : "aiChat"}/>
                                                    <div className="clear-right">{
                                                        message.byUser || message.message.length > 0 ? (
                                                            <Markdown>{message.message.replace("NO_RELEVANT_DATA", "")}</Markdown>
                                                        ) : (
                                                            <div className="flex items-center space-x-1">
                                                                <div
                                                                    className="size-3 bg-gray-900 rounded-full animate-bounce"></div>
                                                                <div
                                                                    className="size-3 bg-gray-900 rounded-full animate-bounce200"></div>
                                                                <div
                                                                    className="size-3 bg-gray-900 rounded-full animate-bounce400"></div>
                                                            </div>
                                                        )
                                                    }</div>
                                                </div>
                                                {chatMode !== ChatMode.PITCH_FABRICE && !message.byUser && <>
                                                    {message.topSources.length > 0 && !message.message?.includes("NO_RELEVANT_DATA") &&
                                                        <div>
                                                            <p className="text-xl text-gray-700 font-medium my-3">Top
                                                                Sources</p>
                                                            <div
                                                                className="grid grid-cols-[repeat(2,_185px)] md:grid-cols-[repeat(auto-fit,_185px)] gap-5">
                                                                {
                                                                    message.topSources?.map((source, index) => (
                                                                        <div key={`source-${index}`}
                                                                             className="grid-cols-subgrid">
                                                                            <a className="link" href={source.link}
                                                                               target="_blank" rel="noopener noreferrer">
                                                                                <LazyLoadImage
                                                                                    className="w-full h-24 object-cover rounded-md mb-2"
                                                                                    src={source.img} alt={source.title}/>
                                                                                {source.title}
                                                                            </a>
                                                                        </div>
                                                                    ))
                                                                }
                                                                {loading && index === selectedChat.messages.length - 1 &&
                                                                    <div
                                                                        className="animate-pulse grid-cols-subgrid gap-2 grid">
                                                                        <div className="h-24 bg-slate-200 rounded-md"></div>
                                                                        <div className="h-4 bg-slate-200 rounded-md"></div>
                                                                    </div>}
                                                            </div>
                                                        </div>}
                                                    {message.status !== "complete" && !loading && <button className={`cursor-pointer flex align-center gap-2 ml-auto w-fit text-lg
                       ${message.status === "error" ? "bg-red-300 text-red-700 border border-red-600" : "bg-gray-200 text-gray-500 border border-gray-500"} my-3 p-2 rounded-lg`}
                                                                                                          onClick={() => {
                                                                                                              const lastMessageIndex = selectedChat.messages.length - 1;
                                                                                                              setChatData((prevChatData) => {
                                                                                                                  const newChatData: ChatData = JSON.parse(JSON.stringify(prevChatData));
                                                                                                                  const selectedChat = newChatData.chats.find((chat) => chat.id === newChatData.selected);
                                                                                                                  if (selectedChat) {
                                                                                                                      if (lastMessageIndex >= 0) {
                                                                                                                          selectedChat.messages[lastMessageIndex].message = '';
                                                                                                                          selectedChat.messages[lastMessageIndex].topSources = [];
                                                                                                                          selectedChat.messages[lastMessageIndex].status = 'incomplete';
                                                                                                                      }
                                                                                                                  }
                                                                                                                  return newChatData;
                                                                                                              });
                                                                                                              fetchApis(selectedChat.messages[lastMessageIndex - 1].message);
                                                                                                          }}>
                                                        {message.status === "error" ? 'Oops, something went wrong. Please try again.' : 'Continue generating'}
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                             viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor"
                                                             className="size-6">
                                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"/>
                                                        </svg>

                                                    </button>}
                                                </>}

                                            </article>
                                        ))
                                    }</> : <div
                                        className="grid gap-5 m-auto grid-cols-2 sm:grid-cols-[repeat(auto-fill,_250px)] md:grid-cols-[repeat(2,_250px)] pb-8">
                                        {((chatMode === ChatMode.PITCH_FABRICE) ? [
                                            "What are fair Seed & Series A valuations?", "What are the biggest mistakes marketplace founders make?"
                                        ] : questions).map((question, index) => (
                                            <div key={index}
                                                 className="cursor-pointer p-3 min-h-[80px] border text-slate-600 border-slate-400 rounded-xl shadow-md"
                                                 onClick={() => submitPrompt(question)}>
                                                {question}
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                        }

                        <div
                            className="flex flex-col items-center w-full absolute bottom-36 sm:bottom-32 md:bottom-28 left-1/2 transform -translate-x-1/2 gap-2">
                            {
                                showScrollButton &&
                                <img className="size-8 cursor-pointer" src={scrollDown} alt="scrollDown"
                                     onClick={scrollToBottom}/>
                            }
                            <div
                                className={`md:w-full grid gap-4 grid-flow-row md:grid-flow-col ${chatMode === ChatMode.LIVE_CHAT ? "place-content-between" : "place-content-center"} lg:px-5 items-center`}>
                                {chatMode === ChatMode.LIVE_CHAT &&
                                    <div className="order-2 md:order-1">
                                        <LanguageSelection selectedLanguage={selectedLanguage}
                                                           setSelectedLanguage={(value) => {
                                                               setSelectedLanguage(value);
                                                               setGreet(true);
                                                           }}/>
                                    </div>}
                                <div className="order-1 md:order-2 col-span-2 md:col-span-1"><ChatModeSwitch
                                    chatMode={chatMode} setChatMode={(chatMode) => {
                                    setChatMode(chatMode);
                                    setShowScrollButton(false);
                                }}/></div>
                                {!(chatMode === ChatMode.TEXT || chatMode === ChatMode.PITCH_FABRICE) &&
                                    <div className="order-2 md:order-3 text-end">
                                        <button onClick={() => avatarComponentRef.current?.stopAvatarState()}
                                                className={'flex bg-black text-white px-2 gap-2 py-2 rounded-md'}>
                                            {dataStreamLoading && loadingMessage.split(" ").length < 15 ?
                                                <img className="size-6" src={processing} alt="processing"/> :
                                                avatarState === AvatarState.SPEAKING ?
                                                    <img className="size-6" src={speaking} alt="speaking"/> :
                                                    avatarState === AvatarState.LISTENING &&
                                                    <img className="size-6" src={listening} alt="listening"/>}
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                 fill="currentColor"
                                                 className="size-6 rounded-full border border-white p-1">
                                                {
                                                    avatarState === AvatarState.IDLE ? <path fillRule="evenodd"
                                                                                             d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                                                                                             clipRule="evenodd"/>
                                                        : <path fillRule="evenodd"
                                                                d="M4.5 7.5a3 3 0 0 1 3-3h9a3 3 0 0 1 3 3v9a3 3 0 0 1-3 3h-9a3 3 0 0 1-3-3v-9Z"
                                                                clipRule="evenodd"/>
                                                }

                                            </svg>
                                        </button>
                                    </div>}
                            </div>


                        </div>
                        <div className="lg:px-5 pb-2 gap-2">
                            {
                                isRecordingInProgress || transcripting ?
                                    <div className="border border-slate-400 rounded-xl">
                                        {
                                            isRecordingInProgress && <div className="p-2 flex items-center gap-2">
                                                <AiFillCloseCircle size={24} onClick={() => {
                                                    if (isRecordingInProgress) stopRecording();
                                                    clearCanvas();
                                                }}/>
                                                <VoiceVisualizer mainContainerClassName="flex-grow"
                                                                 height={56} controls={recorderControls}
                                                                 isControlPanelShown={false} mainBarColor={'#2f2f2f'}
                                                />
                                                <AiFillCheckCircle size={24} onClick={() => {
                                                    stopRecording();
                                                }}/>
                                            </div>
                                        }
                                        {
                                            transcripting && <div className="p-3 text-center">
                                                <img className="w-4/5 h-12 mx-auto" src={audioWave} alt="transcripting"/>
                                                <p>Transcribing...</p>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <>

                                        <form
                                            className={`${chatMode === ChatMode.LIVE_CHAT && "hidden"} p-2 flex border border-slate-400 rounded-xl gap-2 items-center`}
                                            onSubmit={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                                if (prompt.length > 0 && !loading) {
                                                    submitPrompt(prompt);
                                                    setPrompt("");
                                                }
                                            }}>
                      <textarea
                          className="p-1 flex-grow form-textarea border-none outline-none focus:ring-0 resize-none"
                          placeholder="Enter a prompt"
                          value={prompt}
                          onChange={(e) => setPrompt(e.target.value)}
                          onKeyDown={(event) => {
                              if (event.key === "Enter" && !event.shiftKey) {
                                  event.preventDefault();
                                  if (prompt.length > 0 && !loading) {
                                      submitPrompt(prompt);
                                      setPrompt("");
                                  }
                              }
                          }}/>
                                            <button type="button" onClick={() => startRecording()} className="p-1">
                                                <img src={mic} alt="mic"/>
                                            </button>
                                            <div className="h-6 w-[1px] bg-slate-500 mx-1"/>
                                            <button type="submit"
                                                    disabled={prompt.length === 0 || loading || selectedChat?.messages.at(selectedChat?.messages?.length - 1)?.message?.length === 0}>
                                                <img
                                                    src={prompt.length === 0 || loading || selectedChat?.messages.at(selectedChat?.messages?.length - 1)?.message?.length === 0 ? sendDisabled : send}
                                                    alt="send"/>
                                            </button>
                                        </form>
                                        <small>Fabrice AI is an experimental tool
                                            and
                                            may be
                                            inaccurate at times.
                                            {' '}
                                            Read <a className="underline text-blue-700"
                                                    href={`${process.env.REACT_APP_DISCLAIMER_URL}`}
                                                    target={'_blank'} rel="noreferrer">Disclaimers</a>
                                        </small>
                                    </>
                            }

                        </div>
                    </main>
                </div>
            </div>
            <ToastContainer/>
        </div>

    );

}

export default App;

